import { menuItems } from './config';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BiLogOut } from 'react-icons/bi';
import useAuth from '../../hooks/useAuth';

import './Menu.scss';

const Menu = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <div className="menu-page">
      <div className="menu-wrapper">
        <img
          src="/images/fehu-logo.png"
          alt="fehu investments"
          onClick={() => navigate('/')}
        />
        <div className="menu-list">
          {menuItems.map(item => (
            <NavLink key={item.value} to={item.href}>
              {item.icon && <item.icon className="menu-icon" />}{' '}
              <span className="menu-item-content">{item.value}</span>
            </NavLink>
          ))}
          <a onClick={logout}>
            <BiLogOut /> <span className="menu-item-content">Wyloguj</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Menu;
