import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, Stack } from '@mui/material';
import { ISearchBar, TSearchClients } from './Clients.types';

const ClientsSearchbar: React.FC<ISearchBar> = ({ searchClients, clearFilters }) => {
  const initialValues = {
    companyName: '',
    nip: '',
    phone: '',
    city: '',
    address: '',
  };

  return (
    <div className="Clients-searchbar">
      <h3>Szukaj klientów</h3>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          const searchParams: TSearchClients = {
            companyName: values.companyName || "",
            city: values.city || "",
            address: values.address || "",
            ...(values.nip && { nip: Number(values.nip) }),
            ...(values.phone && { phone: Number(values.phone) }),
          };

          searchClients(searchParams);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="searchbar">
              <Field
                name="companyName"
                as={TextField}
                id="company-name"
                placeholder="Firma"
                variant="outlined"
              />
              <Field
                name="nip"
                as={TextField}
                id="nip"
                placeholder="NIP"
                variant="outlined"
                type="number"
              />
              <Field
                name="phone"
                as={TextField}
                id="phone"
                placeholder="Telefon"
                variant="outlined"
                type="number"
              />
              <Field
                name="city"
                as={TextField}
                id="city"
                placeholder="Miasto"
                variant="outlined"
              />
              <Field
                name="address"
                as={TextField}
                id="address"
                placeholder="Ulica"
                variant="outlined"
              />
            </div>
            <div className="search-btn">
              <Stack spacing={1} direction={'row'}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Szukaj
                </Button>
                <Button type="reset" variant="outlined" color="warning" onClick={clearFilters}>
                  Wyczyść
                </Button>
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClientsSearchbar;