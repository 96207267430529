import { TableBody, TableCell, TableRow } from '@mui/material';
import { ILocationTableRow } from "./locationTable.types";
import Popup from '../../../../modules/Popup/Popup';
import AddEditLocal from '../../../../modules/AddEditLocal/AddEditLocal';
import { useState } from 'react';

const LocationTableRow: React.FC<ILocationTableRow> = ({locationData}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => setIsOpen(false);

    return (
      <>
      <TableBody>
        <TableRow onClick={() => setIsOpen(true)}>
          <TableCell component="th" scope="row">
            {locationData?.localName}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.postalCode} {locationData?.city} ul. {locationData?.address} 
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.phone}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.country}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.email}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.priceDirtyOil}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.priceCanolaOil}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.priceFryingOil}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.pricePalmOil}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.openingTime?.toString()} - {locationData?.closingTime?.toString()}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.selfBiling}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.payment}
          </TableCell>
          <TableCell align="left" size="medium">
            {locationData?.additionalInfo}
          </TableCell>

        </TableRow>
      </TableBody>
      {isOpen && <Popup isOpen={isOpen} handleClose={handleClose}><AddEditLocal handleClose={handleClose} locationData={locationData}/></Popup>}
      </>
      
    );
  
}

export default LocationTableRow;