import axios from 'axios';

class Connector {
  constructor() {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_PATH,
    });

    instance.interceptors.request.use(this.handleRequest, this.handleError);
    instance.interceptors.response.use(this.handleSuccess, this.handleError);

    this.axiosInstance = instance;
  }

  createUrl = ({ route, params = {} }) => {
    if (params.sortBy && Array.isArray(params.sortBy)) {
      params.sortBy = params.sortBy.join(',');
    }
    if (params.order && Array.isArray(params.order)) {
      params.order = params.order.join(',');
    }

    const query = new URLSearchParams(params).toString();
    return query ? `${route}?${query}` : route;
  };

  handleRequest = config => {
    const token = sessionStorage.getItem('authToken');
    config.headers.authorization = token ? `Bearer ${token}` : '';
    return config;
  };

  handleSuccess = response => {
    document.showAlert(false, 'Sukces', 'Operacja zakończona sukcesem');
    return response;
  };

  handleError = error => {
    document.showAlert(true, 'Błąd', error.message);
    console.log({ error });
  };
  // PARAMS EXAMPLE
  // const params = {
  //   limit: 10,
  //   page: 1,
  //   sortBy: ['city:asc', 'name:desc'],
  //   clientId: 123,
  //   city: 'Warsaw',
  // };

  //  ██████╗  ██████╗ ██╗   ██╗████████╗███████╗███████╗
  //  ██╔══██╗██╔═══██╗██║   ██║╚══██╔══╝██╔════╝██╔════╝
  //  ██████╔╝██║   ██║██║   ██║   ██║   █████╗  ███████╗
  //  ██╔══██╗██║   ██║██║   ██║   ██║   ██╔══╝  ╚════██║
  //  ██║  ██║╚██████╔╝╚██████╔╝   ██║   ███████╗███████║
  //  ╚═╝  ╚═╝ ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚══════╝

  getClients = params => {
    return this.axiosInstance.get(
      this.createUrl({ route: '/clients', params })
    );
  };

  getClient = id => {
    return this.axiosInstance.get(this.createUrl({ route: `/clients/${id}` }));
  };

  getClientDataByNip = nip => {
    return this.axiosInstance.get(
      this.createUrl({ route: `/clients/synchronize/${nip}` })
    );
  };

  postNote = data => {
    return this.axiosInstance.post('/notes', data);
  };

  deleteNote = id => {
    return this.axiosInstance.delete(this.createUrl({ route: `notes/${id}` }));
  };
}

export default new Connector();
