import { IAddEditNote } from './AddEditNote.types';
import { TextField, Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Connector from '../../connector';

const AddEditNote: React.FC<IAddEditNote> = ({ handleClose }) => {
  const [note, setNote] = useState<string>('');
  const { id } = useParams();
  const clientId = Number(id);

  const onSave = async () => {
    const body = {
      clientId,
      content: note,
    };

    await Connector.postNote(body);
    handleClose();
  };

  return (
    <div className="AddEditNote-module">
      <TextField
        fullWidth
        variant="outlined"
        multiline
        rows={5}
        value={note}
        onChange={e => setNote(e.target.value)}
      />
      <Stack direction={'row'} spacing={1} mt={1}>
        <Button variant="contained" color="secondary" onClick={onSave}>
          Zapisz
        </Button>
        <Button variant="outlined" color="warning" onClick={handleClose}>
          Anuluj
        </Button>
      </Stack>
    </div>
  );
};

export default AddEditNote;
