import React from 'react';
import { Button } from '@mui/material';
import moment from 'moment';
import { INoteProps } from './notes.types';
import { TableRow, TableCell, TableBody } from '@mui/material';
import Connector from '../../../../connector';

const SingleNote: React.FC<INoteProps> = ({ note, onRefreshClient }) => {
  const date = moment(note?.createdAt).format('YYYY/MM/DD');
  const onDelete = async () => {
    await Connector.deleteNote(note?.id);
    onRefreshClient();
  };

  return (
    <TableBody>
      <TableRow>
        <TableCell>{note?.content}</TableCell>
        <TableCell>{note?.username}</TableCell>
        <TableCell>{date}</TableCell>
        <TableCell>
          <Button color="error" onClick={onDelete}>Usuń</Button>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default SingleNote;
