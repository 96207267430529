import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4A4A4A',
    },
    secondary: {
      main: '#008a0c',
    },
    warning: {
      main: '#868c20'
    }
  },
});

export default theme;
