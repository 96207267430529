import { IMenuItems } from './Menu.types';
import { FaTable } from "react-icons/fa";
import { GrStorage } from "react-icons/gr";
import { FaRoad } from "react-icons/fa";
import { GrDeliver } from "react-icons/gr";

export const menuItems: IMenuItems[] = [
  {
    value: 'Baza klientów',
    href: 'clients',
    icon: FaTable
  },
  {
    value: 'Stany magazynowe',
    href: 'warehouse',
    icon: GrStorage

  },
  {
    value: 'Planowanie tras',
    href: 'planning',
    icon: FaRoad
  },
  {
    value: 'Dostawcy',
    href: 'suppliers',
    icon: GrDeliver
  },
];