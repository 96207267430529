import { Modal, Typography } from '@mui/material';
import { IPopup } from './Popup.types';

import './Popup.scss';

const Popup: React.FC<IPopup> = ({ isOpen, handleClose, children, title = "" }) => {
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose} >
        <div className="Popup-module">
          <Typography variant="h5">{title}</Typography>
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default Popup;
