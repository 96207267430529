import {
  Button,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import './Clients.scss';

import ClientsTable from './ClientsTable/ClientsTable';
import ClientsSearchbar from './ClientsSearchbar';
import Connector from '../../connector';
import { ITableDataProps } from './ClientsTable/clientsTable.types';
import { useEffect } from 'react';
import { TSearchClients } from './Clients.types';

const Clients = () => {
  const [clients, setClients] = useState<ITableDataProps>({ data: [], pagination: { currentPage: 1, pageSize: 10, totalPages: 1, totalRecords: 0 } });
  const [page, setPage] = useState<number>(1);
  const [isFilteredData, setIsFilteredData] = useState<boolean>(false);
  const [searchData, setSearchData] = useState({});

  const onNextPage = () => setPage(page + 1);
  const onPrevPage = () => setPage(page - 1);

  const onClearFilters = () => {
    setIsFilteredData(false);
    setSearchData({});
    fetchData();
  };
  
  const fetchData = async () => {
    const response = await Connector.getClients({page});
    setClients(response?.data);
    setPage(response?.data?.pagination?.currentPage);
  };

  const searchClients = async (searchData: TSearchClients ) => {
    const response = await Connector.getClients({page, ...searchData});
    setClients(response?.data);
    setSearchData(searchData);
    setIsFilteredData(true);
  };

  useEffect(() => {
    if (!isFilteredData) {
      fetchData();
    }

    searchClients(searchData);
  }, [page]);

  return (
    <div className="Clients-page">
      <h2>Baza klientów</h2>
      <div className="Clients-page-wrapper">
        <div className="Clients-header">
          <h3>Lista klientów</h3>
          <div>
            <Stack spacing={1} direction={'row'}>
              <Button variant="contained" color="secondary">
                Dodaj klienta
              </Button>
            </Stack>
          </div>
        </div>
        <ClientsSearchbar searchClients={searchClients} clearFilters={() => onClearFilters()}/>
        {clients?.data?.length !== 0 ? <ClientsTable clientsData={clients} onNextPage={onNextPage} onPrevPage={onPrevPage}/> : <div>Brak rezultatów</div>}
      </div>
    </div>
  );
};

export default Clients;
