import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ITableRowProps } from './clientsTable.types';

const ClientsTableRow: React.FC<ITableRowProps> = ({ clientData }) => {
  const navigate = useNavigate();

  return (
    <TableBody>
      <TableRow onClick={() => navigate(`${clientData?.id}`)}>
        <TableCell component="th" scope="row">
          {clientData?.companyName}
        </TableCell>
        <TableCell align="left" size="medium">
          {clientData?.contactName}
        </TableCell>
        <TableCell align="left" size="medium">
          {clientData?.postalCode} {clientData?.city} ul. {clientData?.address}
        </TableCell>
        <TableCell align="left" size="medium">
          {clientData?.country}
        </TableCell>
        <TableCell align="left" size="medium">
          {clientData?.nip}
        </TableCell>
        <TableCell align="left" size="medium">
          {clientData?.isVat ? "Tak" : "Nie"}
        </TableCell>
        <TableCell align="left" size="medium">
          {clientData?.isSignedContract ? "Tak" : "Nie"}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default ClientsTableRow;
