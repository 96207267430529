import React, { createContext, useState, useEffect } from 'react';
import Login from '../pages/Login/Login';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    sessionStorage.setItem('authToken', '');
    sessionStorage.setItem('refreshToken', '');
  }, []);

  useEffect(() => {
    if (!authToken) return;
    const interval = setInterval(refresh, 1000 * 60 * 14);
    return () => clearInterval(interval);
  }, [authToken]);

  const login = async (login, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/login`,
        { login, password }
      );
      const { authToken, refreshToken } = response.data;

      sessionStorage.setItem('authToken', authToken);
      sessionStorage.setItem('refreshToken', refreshToken);

      setAuthToken(authToken);
      setRefreshToken(refreshToken);
    } catch (error) {
      console.error('Błąd logowania:', error);
    }
  };

  const logout = () => {
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('refreshToken');
    setAuthToken(null);
    setRefreshToken(null);
  };

  const refresh = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/refresh`,
        { refreshToken }
      );
      const { authToken: newAuthToken } = response.data;

      sessionStorage.setItem('authToken', newAuthToken);

      setAuthToken(newAuthToken);
    } catch (error) {
      console.error('Błąd odświeżania tokena:', error);
      logout();
    }
  };

  const authContextValue = {
    authToken,
    refreshToken,
    login,
    logout,
    refresh,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {authToken ? children : <Login />}
    </AuthContext.Provider>
  );
};
