import { Button, Stack } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { TClientParamsID } from './singleClient.types';

import SingleClientForm from './SingleClientForm';
import LocationTable from './LocationTable/LocationTable';
import Notes from './Notes/Notes';

import Popup from '../../../modules/Popup/Popup';
import AddEditLocal from '../../../modules/AddEditLocal/AddEditLocal';

import './SingleClient.scss';

import { useState } from 'react';

import Connector from '../../../connector';
import { useEffect } from 'react';

import { IClient } from '../Clients.types';

const SingleClient = () => {
  const navigate = useNavigate();
  const { id } = useParams<TClientParamsID>();
  const [client, setClient] = useState<IClient>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const fetchData = async () => {
    const response = await Connector.getClient(id);
    setClient(response?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onNipUpdate = async () => {
    const response = await Connector.getClientDataByNip(client?.nip);
    setIsEdit(true);

    const clientData: IClient = {
      ...client,
      ...response.data,
    };

    setClient(clientData);
  };

  const onBack = () => {
    navigate(-1);
  };

  const [isOpen, setisOpen] = useState<boolean>(false);
  const handleClose = () => setisOpen(false);

  return (
    <div className="SingleClient-page">
      <div className="SingleClient-wrapper">
        <h2>Nazwa klienta</h2>
        <div className="SingleClient-header">
          <Button onClick={onBack} color="warning">
            Powrót
          </Button>
          <Stack spacing={1} direction={'row'}>
            <Button variant="contained" color="error">
              Usuń
            </Button>
            <Button variant="contained" color="secondary" onClick={onNipUpdate}>
              Zaktualizuj po NIP
            </Button>
          </Stack>
        </div>
        <SingleClientForm
          client={client || null}
          onEdit={() => setIsEdit(!isEdit)}
          isEdit={isEdit}
        />
        <div className="SingleClient-table">
          <LocationTable locationData={client?.locations || []} />
          <div className="SingleClient-table-btn">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setisOpen(true)}
            >
              Dodaj lokal
            </Button>
          </div>
          <div className="SingleClient-notes">
            <h3>Notatki</h3>
            <div className="SingleClient-notes-wrapper">
              <Notes notes={client?.notes || []} onRefreshClient={() => fetchData()}/>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <Popup handleClose={handleClose} isOpen={isOpen} title="Dodaj lokal">
          <AddEditLocal handleClose={handleClose} />
        </Popup>
      )}
    </div>
  );
};

export default SingleClient;
