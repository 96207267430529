import Root from './Root';
import './App.scss';
import { AuthProvider } from './providers/AuthProvider';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Root />
      </AuthProvider>
    </div>
  );
}

export default App;
