import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, Paper } from '@mui/material';
import LocationTableRow from './LocationTableRow';
import { ILocationProps } from './locationTable.types';
import { ILocation } from '../../Clients.types';

const LocationTable: React.FC<ILocationProps> = ({ locationData }) => {
  return (
    <>
      <h3>Lokale</h3>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300 }}
          size="medium"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Nazwa firmy</TableCell>
              <TableCell align="left">Lokalizacja</TableCell>
              <TableCell align="left">Telefon</TableCell>
              <TableCell align="left">Kraj</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Cena BO</TableCell>
              <TableCell align="left">Cena OR</TableCell>
              <TableCell align="left">Cena Frytura</TableCell>
              <TableCell align="left">Cena OP</TableCell>
              <TableCell align="left">Godziny otwarcia</TableCell>
              <TableCell align="left">Samofakturowanie</TableCell>
              <TableCell align="left">Płatność</TableCell>
              <TableCell align="left">Dodatkowe informacje</TableCell>
            </TableRow>
          </TableHead>
          {locationData?.map((location: ILocation) => (
            <LocationTableRow locationData={location} key={location?.id} />
          ))}
        </Table>
      </TableContainer>
    </>

  )
}

export default LocationTable;