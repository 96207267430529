import { Formik, Form, Field } from 'formik';
import { TextField, Button, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { ISingleClientProps } from './singleClient.types';

const SingleClientForm: React.FC<ISingleClientProps> = ({ client, onEdit, isEdit }) => {
  const initialValues = {
    companyName: '',
    contactName: '',
    address: '',
    city: '',
    country: '',
    postalCode: '',
    nip: '',
    isVat: 0,
    isSignedContract: 0,
    ...client
  };

  return (
    <div className="SingleClient-form">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-wrapper">
              <Field as={TextField} label="Nazwa firmy" name="companyName" value={initialValues?.companyName} disabled={!isEdit} />
              <Field as={TextField} label="Imię i nazwisko" name="contactName" value={initialValues?.contactName} disabled={!isEdit} />
              <Field as={TextField} label="Adres" name="address" value={initialValues?.address} disabled={!isEdit} />
              <Field as={TextField} label="Miasto" name="city" value={initialValues?.city} disabled={!isEdit} />
              <Field as={TextField} label="Kraj" name="country" value={initialValues?.country} disabled={!isEdit} />
              <Field as={TextField} label="Kod pocztowy" name="postalCode" value={initialValues?.postalCode} disabled={!isEdit} />
              <Field as={TextField} label="NIP" name="nip" type="number" value={initialValues?.nip} disabled={!isEdit} />
              <Stack spacing={1} direction={'row'}>
                <Field as={() => (
                  <FormControlLabel
                    control={<Checkbox />}
                    label="VAT"
                    disabled={!isEdit}
                  />
                )} name="isVat" type="checkbox" disabled={!isEdit} />
                <Field as={() => (
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Kontrakt"
                    disabled={!isEdit}
                  />
                )} name="isSignedContract" type="checkbox" disabled={!isEdit} />
              </Stack>


              <Stack spacing={1} direction={'row-reverse'} marginTop={2} width={'100%'}>
                <Button type="submit" variant={isEdit ? "outlined" : "contained"} color="warning" onClick={() => onEdit()}>
                  {isEdit ? 'Anuluj edycję' : 'Edycja'}
                </Button>
                <Button type="submit" variant="contained" color="secondary" disabled={isSubmitting || !isEdit}>
                  Zapisz
                </Button>
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SingleClientForm;