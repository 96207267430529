import { Routes, Route } from 'react-router-dom';

import Clients from './Clients/Clients';
import Warehouses from './Warehouses/Warehouses';
import Suppliers from './Suppliers/Suppliers';
import Planning from './Planning/Planning';
import SingleClient from './Clients/SingleClient/SingleClient';
import Menu from '../components/Menu/Menu';

import './styles.scss';

const Panel = () => {
  return (
    <div className="Panel-page">
      <div className="Panel-wrapper">
        <Menu />
        <div className="content-wrapper">
          <Routes>
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/:id" element={<SingleClient />} />
            <Route path="/planning" element={<Planning />} />
            <Route path="/warehouse" element={<Warehouses />} />
            <Route path="/suppliers" element={<Suppliers />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Panel;
