import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  TextField,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
} from '@mui/material';
import { hours, minutes } from './config';

import './AddEditLocal.scss';
import { IAddEditLocal } from './AddEditLocal.types';

const AddEditLocal: React.FC<IAddEditLocal> = ({
  handleClose,
  locationData,
}) => {
  const initialValues = {
    country: '',
    postalCode: '',
    city: '',
    localName: '',
    address: '',
    phone: '',
    email: '',
    priceDirtyOil: '',
    priceCanolaOil: '',
    priceFryingOil: '',
    pricePalmOil: '',
    openingTime: '',
    closingTime: '',
    selfBiling: false,
    payment: 0,
    ...locationData,
  };

  const timeOptions = hours.flatMap(hour =>
    minutes.map(minute => `${hour}:${minute}`)
  );

  return (
    <div className="AddEditLocal-module">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="AddEditLocal-form">
              <Field
                name="country"
                as={TextField}
                label="Kraj"
                variant="outlined"
              />
              <Field
                name="city"
                as={TextField}
                label="Miasto"
                variant="outlined"
              />
              <Field
                name="address"
                as={TextField}
                label="Ulica"
                variant="outlined"
              />
              <Field
                name="postalCode"
                as={TextField}
                label="Kod pocztowy"
                variant="outlined"
              />
              <Field
                name="localName"
                as={TextField}
                label="Nazwa lokalu"
                variant="outlined"
              />
              <Field
                name="phone"
                as={TextField}
                label="Telefon"
                variant="outlined"
                type="number"
              />
              <Field
                name="email"
                as={TextField}
                label="Email"
                variant="outlined"
              />
                <Field
                  name="priceDirtyOil"
                  as={TextField}
                  label="Cena BO"
                  variant="outlined"
                  type="number"
                />
                <Field
                  name="priceCanolaOil"
                  as={TextField}
                  label="Cena OR"
                  variant="outlined"
                  type="number"
                />
                <Field
                  name="priceFryingOil"
                  as={TextField}
                  label="Cena Frytura"
                  variant="outlined"
                  type="number"
                />
                <Field
                  name="pricePalmOil"
                  as={TextField}
                  label="Cena OP"
                  variant="outlined"
                  type="number"
                />

              <Stack
                direction={'row'}
                spacing={2}
              >
                <Field
                  name="openingTime"
                  as={() => (
                    <Select
                      sx={{width: '49%'}}
                      name="openingTime"
                      label="Godziny"
                      variant="outlined"
                      value={timeOptions?.[0]}
                    >
                      <MenuItem value="" disabled>
                        Godziny otwarcia
                      </MenuItem>
                      {timeOptions?.map(time => (
                        <MenuItem key={time} value={time}>{time}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <Field
                  as={() => (
                    <Select
                      sx={{width: '49%'}}
                      name="closingTime"
                      label="Godziny zamknięcia"
                      variant="outlined"
                      value={timeOptions?.[20]}
                    >
                      <MenuItem value="" disabled>
                        Godziny zamknięcia
                      </MenuItem>
                      {timeOptions?.map(time => (
                        <MenuItem key={time} value={time}>{time}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Stack>

              <Field
                name="additionalInfo"
                as={() => (
                  <TextField
                    label="Dodatkowe informacje"
                    variant="outlined"
                    multiline={true}
                    rows={4}
                  />
                )}
                label="Dodatkowe informacje"
                variant="outlined"
              />
              <Stack >
                <Field
                  name="selfBiling"
                  type="checkbox"
                  as={() => (
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Samofakturowanie"
                    />
                  )}
                />
                <Field
                  name="payment"
                  as={() => (
                    <RadioGroup>
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Karta"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Gotówka"
                      />
                    </RadioGroup>
                  )}
                  label="Rodzaj płatności"
                  variant="outlined"
                />
              </Stack>
            </div>

            <Stack direction={'row'} spacing={1}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
              >
                Zapisz
              </Button>
              <Button type="reset" variant="contained" color="error">
                Usuń
              </Button>
              <Button variant="outlined" color="warning" onClick={handleClose}>
                Anuluj
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddEditLocal;
