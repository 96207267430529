import {
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Typography,
} from '@mui/material';
import { IClient } from '../Clients.types';
import { ITableProps } from './clientsTable.types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import ClientsTableRow from './ClientsTableRow';

const ClientsTable: React.FC<ITableProps> = ({
  clientsData,
  onNextPage,
  onPrevPage,
}) => {
  const { totalRecords, currentPage, pageSize, totalPages } =
    clientsData?.pagination;
  const recordsFrom = pageSize * currentPage - 9;
  const recordsTo = pageSize * currentPage;

  return (
    <div className="Clients-table">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Nazwa firmy</TableCell>
              <TableCell align="left">Właściciel</TableCell>
              <TableCell align="left">Adres</TableCell>
              <TableCell align="left">Kraj</TableCell>
              <TableCell align="left">NIP</TableCell>
              <TableCell align="left">VAT</TableCell>
              <TableCell align="left">Kontrakt</TableCell>
            </TableRow>
          </TableHead>
          {clientsData?.data?.map((client: IClient) => (
            <ClientsTableRow clientData={client} key={client?.id} />
          ))}
        </Table>
      </TableContainer>
      <div className="pagination">
        <Typography variant="overline">
          {recordsFrom} - {currentPage === totalPages ? totalRecords : recordsTo} / {totalRecords}
        </Typography>
        <span
          onClick={() => {
            currentPage !== 1 && onPrevPage();
          }}
          className={currentPage === 1 ? 'disabled' : ''}
        >
          <FaChevronLeft />
        </span>
        <span
          onClick={() => {
            currentPage !== totalPages && onNextPage();
          }}
          className={currentPage === totalPages ? 'disabled' : ''}
        >
          <FaChevronRight />
        </span>
      </div>
    </div>
  );
};

export default ClientsTable;
